import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSortModule,
    MatSelectModule,
    MatFormFieldModule
    
  ],
  exports:[
    CommonModule,
    MatSortModule,
    MatSelectModule,
    MatFormFieldModule
  ]
  
})
export class UiLibrariesModule { }
