import { Component } from '@angular/core';
import { ECLOLAB } from '../../shared/constants/app.constants';
import { TranslationService } from '../../features/tvd-setup/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import{environment} from '../../../../src/environments/environment';


@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrl: './unauthorized-user.component.scss'
})
export class UnauthorizedUserComponent {
  UnAuthICON = ECLOLAB;
  ECOLAB_WEB = environment.ECOLAB_WEB;
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService
  ) {}
  returnToMain() {
    window.location.href = this.ECOLAB_WEB;
  }
  ngOnInit() {
    this.translateService.selectedLanguage();
  }
}
