import { Injectable } from '@angular/core';
import { ApmBase } from '@elastic/apm-rum';
import { ApmErrorHandler, ApmService } from '@elastic/apm-rum-angular';
@Injectable()
export class GlobalErrorHandler extends ApmErrorHandler {
  apmbase: ApmBase;
  constructor(
    apmservice: ApmService) {
    super(apmservice.apm);
    this.apmbase = apmservice.apm;
  }
  override handleError(error: any): void {
    // Call the APMErrorHandler's handleError method
    super.handleError(error);
    this.apmbase.captureError(`Custom APM Error:
        ${(typeof (error) === 'string')
          ? error : error.message}`);
  }
}
