import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';
import { environment } from '../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { UserPreferenceService } from './features/tvd-setup/services/user-preference.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'  
})
export class AppComponent {
  title = 'OneCustomer';
  status: any;
  fetchPreferences: any;

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.authService.checkLoggedOutService().subscribe((info) => {
      this.status = info?.data?.login?.isLoggedOut;
      if (this.status) {
        this.authService.logoutTVDAPP(true);
        this.msal.logoutRedirect({
          postLogoutRedirectUri: environment.ECOLAB_WEB,
        });
        this.msal.logout();
      }
    });
  }
  constructor(
    private authService: AuthenticationService,
    private msal: MsalService,
    private userPreferenceService: UserPreferenceService) { }

  ngOnInit(): void {
    this.fetchUserPreferences();
  }
  fetchUserPreferences(): any {
    // Subscribe to user preferences
    this.userPreferenceService.getUserPreferences().subscribe(preferences => {
      this.fetchPreferences = preferences
    });
  }
}